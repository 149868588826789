import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { arrowIconClassByLanguage, getUrlWithoutParams } from '@app/helpers';
import { TemplateRef, ViewChild } from '@angular/core';
import { BannerMessageInterface } from '@app/interfaces';
import { FinancialRecommendation } from '@app/models/financial-recommendation';
import { Beneficiary } from '@app/models';
import { ProjectUnit } from '@app/models';
import { SakaniSessionService } from '@app/base/services/ui/sakani-session.service';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingTokenService } from '@app/base/services/ui/booking-token.service';
import { OffplanBannerMessageService } from '@app/base/services/ui/offplan-banner-message.service';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { SakaniModalService } from '@app/base/services/ui/sakani-modal.service';
import { BeneficiaryService } from '@app/base/services/http/beneficiary.service';
import { BookingSession } from '@app/models/booking-session';
import { LoadingEventService } from '@app/base/services/ui/loading-events.service';
import { BookingSessionService } from '@app/base/services/http/booking-session.service';
import { ActivityTrackingService } from '@app/base/services/socket/activity-tracking.service';
import { EventTrackingActivity } from '@app/enums';
import { ProjectTypes } from '@app/enums';

@UntilDestroy()
@Component({
  selector: 'app-banner-message',
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.scss'],
})
export class BannerMessageComponent implements OnInit {
  beneficiary!: Beneficiary;
  blockReasonMessage?: BannerMessageInterface | undefined;
  isBookingExpired?: boolean;
  bookingSession: any;
  durationTimer?: number;
  currentLang = 'ar';
  eventTrackingActivity = EventTrackingActivity;
  previousUrl = '';

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    if (this.bookingTokenService.currentUnitId() && !this.bookingTokenService.isExpired()) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  @Input() subHeader!: TemplateRef<any>;
  @Input() bgClass!: string;
  @Input() headerTextClass!: string;
  @Input() iconClass!: string;
  isCollapsed = true;
  @Output() callAction = new EventEmitter<string>();

  @ViewChild('forWordToPageTpl') forWordToPageTpl!: TemplateRef<any>;
  forwardTo!: {
    content: string;
    actionText: string;
    actionURL: string;
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    private sakaniSessionService: SakaniSessionService,
    private bookingTokenService: BookingTokenService,
    private bannerMessageService: OffplanBannerMessageService,
    private bookingSessionService: BookingSessionService,
    private beneficiaryService: BeneficiaryService,
    private sakaniModalService: SakaniModalService,
    private loadingEventService: LoadingEventService,
    private activityTracking: ActivityTrackingService
  ) {
    this.currentLang = this.translate.currentLang;
    this.previousUrl = this.router.url;

    // filter navigation end router event
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res: any) => {
      // not update when queryparam change but url not change
      if (this.isUrlChanged(res.url)) {
        this.isCollapsed = true;
        if (!this.bookingSession) {
          this.blockReasonMessage = undefined;
        } else {
          this.bannerMessageService.updateBannerBooking();
        }
      }
      this.previousUrl = res.url;
    });
  }

  isUrlChanged(currentUrl: string) {
    return getUrlWithoutParams(this.router, this.previousUrl) !== getUrlWithoutParams(this.router, currentUrl);
  }

  ngOnInit(): void {
    this.showBannerListen();
  }

  showBannerListen() {
    this.sakaniSessionService.session$.pipe(untilDestroyed(this)).subscribe((s) => {
      if (s == null) {
        this.bookingTokenService.clear();
      }
    });

    this.bannerMessageService.showExtraContentSub.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isCollapsed = value != true;
    });

    this.bannerMessageService.showMessageBannerSubs.pipe(untilDestroyed(this)).subscribe((bannerData) => {
      // workaround avoid error NG100 expression check after view init
      setTimeout(() => {
        this.blockReasonMessage = bannerData;
        if (this.isCollapsed == false && !bannerData) {
          this.isCollapsed = true;
        }
      }, 0);
    });

    this.bookingTokenService.bookingTokenSubs
      .pipe(untilDestroyed(this))
      .subscribe((bookingSessionObj: BookingSession) => {
        if (!this.sakaniSessionService.currentSession) {
          return;
        }

        if (!bookingSessionObj) {
          this.bookingSession = null;
          this.bannerMessageService.resetMessage();
          return;
        }

        if (
          !this.bookingTokenService.hasToken() &&
          bookingSessionObj!.availableUnitsCount != null &&
          bookingSessionObj!.availableUnitsCount <= 0 &&
          bookingSessionObj!.selectedUnitsCount == 0 &&
          bookingSessionObj.project_type !== ProjectTypes.LANDS_MOH_LAND
        ) {
          this.showFullyBookedMsg(bookingSessionObj);
          return;
        }

        if (
          !this.bookingTokenService.hasToken() &&
          bookingSessionObj!.currentQueuePosition != null &&
          bookingSessionObj!.currentQueuePosition <= 0
        ) {
          this.refetchBookingSessionThroughBeneficiary();
          return;
        }

        this.durationTimer = this.bannerMessageService.durationInSeconds();
        if (this.bookingTokenService.hasToken() && !this.durationTimer) {
          // location.reload();
          console.log('#TODO: reload issue');
        }

        this.bookingSession = bookingSessionObj;
        this.isBookingExpired = this.bookingTokenService.isExpired();
        this.bannerMessageService.updateBannerBooking();
      });
  }

  refetchBookingSessionThroughBeneficiary() {
    this.beneficiaryService.me().subscribe((_bene) => {});
  }

  redirectToOtherPage(messageObj: BannerMessageInterface) {
    if (messageObj.path) {
      return this.router.navigate([messageObj.path], { queryParams: { returnUrl: this.router.url } });
    }
    if (messageObj.externalPath) {
      return (window.location.href = messageObj.externalPath);
    }
  }

  arrowIconClass(): string {
    return arrowIconClassByLanguage(this.currentLang);
  }

  clickAction(btnName: string) {
    this.callAction.emit(btnName);
  }

  isLogged(): boolean {
    return this.sakaniSessionService?.isAuthenticated;
  }

  hasBookingToken(): boolean {
    return this.bookingTokenService.hasToken();
  }

  cancelBooking(): void {
    const data = {
      headerText: this.translate.instant('PROJECT_PAGE.BOOKING.CANCEL_TITLE'),
      message: this.translate.instant('PROJECT_PAGE.BOOKING.CANCEL_POPUP'),
      confirmButtonText: this.translate.instant('PROJECT_PAGE.BOOKING.CONTINUE'),
      closeButtonText: this.translate.instant('COMMON.CANCEL'),
    };
    const confirm = this.sakaniModalService.showMessageModal(ModalComponent, data);
    confirm.closed.subscribe((result) => {
      if (result && result.closed) {
        const projectId = this.bookingTokenService?.currentProjectId();
        this.loadingEventService.start();
        this.activityTracking.gaLogEvent(this.eventTrackingActivity.BANNER_BOOKING_CANCEL);
        this.bannerMessageService.callCancelBooking(projectId).subscribe(() => {
          this.loadingEventService.complete();
        });
      }
    });
  }

  leaveQueue(): void {
    const data = {
      headerText: this.translate.instant('COMMON.CANCEL_TITLE'),
      message: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.CANCEL_POPUP'),
      confirmButtonText: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.STAY_IN_QUEUE'),
      closeButtonText: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.LEAVE_QUEUE'),
    };
    const confirm = this.sakaniModalService.showMessageModal(ModalComponent, data);
    confirm.closed.subscribe((result) => {
      if (result && result.closed) {
        this.bookingTokenService.leaveQueue().subscribe();
      }
    });
  }

  dismiss(): void {
    this.bannerMessageService.dismiss();
  }

  continueBooking(): void {
    this.isCollapsed = true;
    this.bannerMessageService.continueBooking();
  }

  returnToProject(): void {
    this.isCollapsed = true;
    this.bannerMessageService.returnToProject();
  }

  bookingTimedOut(_data?: any) {
    if (!this.bookingTokenService.hasToken()) {
      return;
    }
    this.isBookingExpired = true;
    this.blockReasonMessage = this.bannerMessageService.updateBannerBooking();
    if (this.bannerMessageService.isOnCurrentProjectBooking()) {
      this.bannerMessageService.returnToProject();
    }
  }

  updateCurrentTimmer(timer?: any) {
    this.bannerMessageService.updateCurrentTimmer(timer);
  }

  showFullyBookedMsg(bookingSessionObj: BookingSession) {
    const data = {
      headerText: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.UNITS_ARE_FULLY_BOOKED'),
      message: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.UNITS_ARE_FULLY_BOOKED_DESCRIPTION'),
      template: this.forWordToPageTpl,
    };

    this.forwardTo = {
      actionText: '',
      actionURL: '',
      content: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.UNITS_ARE_FULLY_BOOKED_DESCRIPTION'),
    };

    if (bookingSessionObj?.waiting_list_enable) {
      this.forwardTo.actionText = this.translate.instant('PROJECT_PAGE.REGISTER_WAITING_LIST.JOIN_WAITING_LIST');
      this.forwardTo.actionURL = `/offplan-projects/${bookingSessionObj?.project_id}`;
    } else {
      this.forwardTo.actionText = this.translate.instant('COMMON.MARKETPLACE_BTN');
      this.forwardTo.actionURL = '/marketplace';
    }

    console.log('show offplan banner');
    const confirm = this.sakaniModalService.showTemplateModalComponent(ModalComponent, data);
    confirm.dismissed.subscribe((result) => {
      this.handleLeaveQueueAndReloadPage();
    });
  }

  navigateToActionUrl(): void {
    this.sakaniModalService.dismissAll();
    this.handleLeaveQueueAndReloadPage();
  }

  handleLeaveQueueAndReloadPage() {
    this.beneficiaryService.me().subscribe((bene) => {
      if (bene.active_booking_session) {
        this.bookingTokenService.leaveQueue().subscribe((_data) => {
          this.navigateToTargetPage();
        });
      } else {
        this.navigateToTargetPage();
      }
    });
  }

  navigateToTargetPage(): void {
    const currentPath = this.router.url.split('/');
    if (this.forwardTo.actionURL === `/${currentPath[1]}/${currentPath[2]}`) {
      location.reload();
    } else {
      this.router.navigateByUrl(this.forwardTo.actionURL);
    }
  }
}

import { Injectable } from '@angular/core';
import { BookingPaymentOption, BookingStatus, LandContractType } from '@app/enums';
import { createPayload } from '@app/helpers';
import { Endpoints } from '@app/helpers/endpoints';
import { BookingCancelReasonForm, ParamsInterface } from '@app/interfaces';
import { BookingPreCondition } from '@app/interfaces/booking-precondition.interface';
import { ReserveUnitCmd } from '@app/interfaces/reserve-unit-cmd.interface';
import {
  Annex,
  Booking,
  BookingRequest,
  BuildingPermit,
  Conveyance,
  JsonApi,
  LandSubsidyContract,
  MyBooking,
  Project,
  ProjectUnit,
} from '@app/models';
import { ConstructionCert } from '@app/models/construction_certs';
import { UnsubsidizedBooking } from '@app/models/unsubsidized_booking';
import { ResultList } from '@app/shared/types';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { QueryPollingService } from './query-polling.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private reservedUnitSubject = new BehaviorSubject<ProjectUnit | undefined>(undefined);
  public reservedUnitObs = this.reservedUnitSubject.asObservable();

  private mainIntermediaryV4Url = Endpoints.MAIN_INTERMEDIARY_V4;
  private mainIntermediaryV3Url = Endpoints.MAIN_INTERMEDIARY_V3;
  private mainIntermediaryV2Url = Endpoints.MAIN_INTERMEDIARY_API_V2;
  private bookingV3Url = Endpoints.MAIN_INTERMEDIARY_V3 + '/bookings';
  public bookingSetFromLandsActionBox!: Booking;

  // Obs with caching
  private downloadPriceQuotationDocumentReq?: Observable<any>;
  private downloadSaleContractDocumentReq?: Observable<any>;
  private downloadUnitContractDocumentReq?: Observable<any>;
  private downloadIneligibleBeneficiaryContractDocumentReq?: Observable<any>;
  private downloadFeeInvoiceDocumentReq?: Observable<any>;
  private downloadBuildingConstructionPermitReq?: Observable<any> = undefined;
  private downloadConstructionCertificateReq?: Observable<any> = undefined;
  private downloadConveyanceRequestDocumentReq?: Observable<any> = undefined;
  private downloadLandContractByTypeReq = new Map<LandContractType, Observable<any>>();

  constructor(private api: ApiService, private queryPollingService: QueryPollingService) {}

  get currentReservedUnit(): ProjectUnit | undefined {
    return this.reservedUnitSubject.value;
  }

  buildUnitWithProjectObj(response: any) {
    const projectUnit = JsonApi.parseJsonApi(ProjectUnit, response.data, response.included);
    if (projectUnit && projectUnit.project) {
      projectUnit.project = Object.assign(new Project(), projectUnit.project);
    }
    return projectUnit;
  }

  getMyBookingList(params: ParamsInterface): Observable<ResultList<MyBooking[]>> {
    const url = `${this.mainIntermediaryV4Url}/beneficiary/me/bookings`;
    return this.api.apiGet(url, undefined, params).pipe(
      map((res: any) => {
        const bookings = res?.data?.map((mb: any) => JsonApi.parseJsonApi(MyBooking, mb, res?.included));
        return {
          data: bookings,
          total: res?.meta?.record_count,
          totalPage: res?.meta?.page_count,
          extraData: res?.meta?.total_bookings,
        };
      })
    );
  }

  getLastedBookingConveyance(): Observable<MyBooking> {
    const url = `${this.mainIntermediaryV4Url}/beneficiary/me/latest_booking_conveyance`;
    return this.api.apiGet(url, undefined, undefined).pipe(
      map((res: any) => {
        const booking = JsonApi.parseJsonApi(MyBooking, res.data, res.included);
        return booking;
      })
    );
  }

  getBookingDetail(id: string, params: ParamsInterface): Observable<Booking> {
    const url = `${this.mainIntermediaryV4Url}/beneficiary/bookings/${id}`;
    return this.api.apiGet(url, undefined, params).pipe(
      map((res) => {
        const booking = JsonApi.parseJsonApi(Booking, res.data, res.included);

        // @Todo: BE should not return active_subsidy_contract and offplan_housing_subsidy_contract for ineligible booking
        if (booking.for_ineligible_user) {
          delete booking.active_subsidy_contract;
          delete booking.offplan_housing_subsidy_contract;
        }

        return booking;
      })
    );
  }

  getUnsubsidizedBookingDetail(id: string, params: ParamsInterface): Observable<UnsubsidizedBooking> {
    const url = `${this.mainIntermediaryV4Url}/beneficiary/unsubsidize_bookings/${id}`;
    return this.api.apiGet(url, undefined, params).pipe(
      map((res) => {
        const booking = JsonApi.parseJsonApi(UnsubsidizedBooking, res.data, res.included);
        return booking;
      })
    );
  }

  cancelBookingUrl(booking: Booking): string {
    if (booking.project?.isOffPlan) {
      return `${this.mainIntermediaryV4Url}/bookings/offplans/${booking.id}/cancel`;
    } else if (booking.project?.isReadyMade) {
      return `${this.mainIntermediaryV3Url}/bookings/readymades/cancel`;
    } else if (booking.project?.isLand) {
      return `${this.mainIntermediaryV3Url}/bookings/lands/cancel`;
    }

    return '';
  }

  cancelBooking(data: BookingCancelReasonForm, booking: Booking, otpCode?: string): Observable<Booking> {
    const payload = {
      data: {
        type: 'bookings',
        attributes: data,
      },
    };

    if (otpCode) {
      (payload as any).otp = otpCode;
    }

    return this.api.apiPut(this.cancelBookingUrl(booking), '', payload);
  }

  unsubsidizePreBookConditionCheck(unitCode: string): Observable<any> {
    const payload = createPayload({ unit_code: unitCode }, 'unsubsidize_bookings');
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/unsubsidize_bookings/precondition_check`, payload);
  }

  preBookConditionCheck(nin: string, projectId?: string | number): Observable<BookingPreCondition> {
    const payload = createPayload({ national_id_number: nin, project_id: projectId }, 'beneficiary_sessions');
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/precondition_check`, payload).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  preCancelConditionCheck(bookingID: number) {
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/${bookingID}/cancel_precondition_check`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createUnsubsidizeBooking(unitCode: string) {
    const payload = createPayload({ unit_code: unitCode }, 'unsubsidize_bookings');
    return this.api
      .apiPost(`${this.mainIntermediaryV3Url}/unsubsidize_bookings`, payload)
      .pipe(map((res: any) => JsonApi.parseJsonApi(UnsubsidizedBooking, res.data)));
  }

  createBooking(
    unit: ProjectUnit,
    type: 'lands' | 'offplans' | 'readymades',
    payment_option?: BookingPaymentOption,
    bank_cr_number = null
  ): Observable<Booking> {
    const payload = createPayload(
      {
        unit_code: unit.unit_code,
        payment_option,
        bank_cr_number,
      },
      'bookings'
    );
    return this.api.apiPost(`${this.bookingV3Url}/${type}`, payload).pipe(
      map((res: any) => {
        return JsonApi.parseJsonApi(Booking, res.data);
      })
    );
  }

  createLandBooking(unit: ProjectUnit): Observable<Booking> {
    const payload = createPayload(
      {
        unit_code: unit.unit_code,
      },
      'bookings'
    );
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/lands`, payload).pipe(
      map((res: any) => {
        return JsonApi.parseJsonApi(Booking, res.data);
      })
    );
  }

  createOffplanBooking(unit: ProjectUnit): Observable<Booking> {
    const type = 'offplans';
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/${type}`, {}).pipe(
      map((res: any) => {
        return JsonApi.parseJsonApi(Booking, res.data);
      })
    );
  }

  createOffplanBookingWithAzm(unit: ProjectUnit, type: string = 'offplans'): Observable<any> {
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/${type}/create_with_azm_invoice`, {}).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  downloadPriceQuotationDocument(refresh = false) {
    if (!this.downloadPriceQuotationDocumentReq || refresh) {
      this.downloadPriceQuotationDocumentReq = this.api
        .apiDownload(`${this.mainIntermediaryV3Url}/beneficiary/price_quotations/print`, 'application/pdf')
        .pipe(shareReplay(1));
    }
    return this.downloadPriceQuotationDocumentReq;
  }

  downloadV4PriceQuotationDocument(priceQuotationId: string | number | undefined, refresh = false) {
    if (!this.downloadPriceQuotationDocumentReq || refresh) {
      this.downloadPriceQuotationDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV4Url}/beneficiary/price_quotations/${priceQuotationId}/print`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadPriceQuotationDocumentReq;
  }

  downloadUnitContractDocument(bookingId: string | number, refresh = false) {
    if (!this.downloadUnitContractDocumentReq || refresh) {
      this.downloadUnitContractDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV4Url}/bookings/offplans/${bookingId}/download_contract`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadUnitContractDocumentReq;
  }

  downloadIneligibleBeneficiaryContractDocument(bookingId: string | number, refresh = false) {
    if (!this.downloadIneligibleBeneficiaryContractDocumentReq || refresh) {
      this.downloadIneligibleBeneficiaryContractDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV4Url}/bookings/offplans/${bookingId}/download_ineligible_beneficiary_contract`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadIneligibleBeneficiaryContractDocumentReq;
  }

  downloadFeeInvoiceDocument(sadadInvoiceNumber: string | number, refresh = false) {
    if (!this.downloadFeeInvoiceDocumentReq || refresh) {
      this.downloadFeeInvoiceDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV3Url}/beneficiary/invoices/${sadadInvoiceNumber}/print`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadFeeInvoiceDocumentReq;
  }

  reserveUnit(unit: ProjectUnit): Observable<ProjectUnit> {
    const payload = createPayload({ unit_code: unit.unit_code }, '', 'units');
    return this.api.apiPost(`${Endpoints.MAIN_INTERMEDIARY_V3}/units/reserve`, payload).pipe(
      map((res: any) => {
        const unit = this.buildUnitWithProjectObj(res);
        this.reservedUnitSubject.next(unit);
        return unit;
      })
    );
  }

  // TODO: Rename function to `reserveUnitAsync()` ???
  // TODO: Or change the API route `v4/units/reserve` to `v4/units/reserve/offplan` ???
  reserveUnitAsync(unit: ProjectUnit): Observable<ReserveUnitCmd> {
    const payload = createPayload({ unit_code: unit.unit_code });
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/units/reserve`, payload).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  releaseUnit(unit: ProjectUnit): Observable<ProjectUnit> {
    const payload = createPayload({ unit_code: unit.unit_code }, '', 'units');
    return this.api.apiPost(`${Endpoints.MAIN_INTERMEDIARY_V3}/units/release`, payload).pipe(
      map((res: any) => {
        const unit = this.buildUnitWithProjectObj(res);
        this.reservedUnitSubject.next(undefined);
        return unit;
      })
    );
  }

  releaseOffplanUnit(): Observable<ProjectUnit> {
    return this.api.apiPut(`${this.mainIntermediaryV4Url}/units/release`).pipe(
      map((res: any) => {
        const unit = this.buildUnitWithProjectObj(res);
        this.reservedUnitSubject.next(undefined);
        return unit;
      })
    );
  }

  offplanCancelBookingJourney(projectId: number) {
    return this.api
      .apiPut(`${this.mainIntermediaryV4Url}/bookings/offplans/cancel_booking_journey`, '', { project_id: projectId })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  landCancelBookingJourney(projectId: number) {
    return this.api
      .apiPut(`${this.mainIntermediaryV4Url}/bookings/lands/cancel_booking_journey`, '', { project_id: projectId })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  downloadBuildingConstructionPermit(refresh = false) {
    if (!this.downloadBuildingConstructionPermitReq || refresh) {
      this.downloadBuildingConstructionPermitReq = this.api
        .apiDownload(`${this.bookingV3Url}/lands/building_permits/download`, 'application/pdf')
        .pipe(shareReplay(1));
    }
    return this.downloadBuildingConstructionPermitReq;
  }

  downloadConstructionCertificate(refresh = false) {
    if (!this.downloadConstructionCertificateReq || refresh) {
      this.downloadConstructionCertificateReq = this.api
        .apiDownload(`${this.bookingV3Url}/lands/construction_certs/download`, 'application/pdf')
        .pipe(shareReplay(1));
    }
    return this.downloadConstructionCertificateReq;
  }

  downloadConveyanceRequestDocument(conveyanceId: number, refresh = false) {
    if (!this.downloadConveyanceRequestDocumentReq || refresh) {
      this.downloadConveyanceRequestDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV2Url}/conveyances/${conveyanceId}/download_moh_deed_document`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadConveyanceRequestDocumentReq;
  }

  downloadLandContractByType(bookingStatus: BookingStatus, type?: LandContractType, refresh = false) {
    let action = 'preview';
    switch (bookingStatus) {
      case BookingStatus.BUILDING_PERMIT_PHASE:
      case BookingStatus.CONSTRUCTION_PHASE:
      case BookingStatus.CONTRACTED:
      case BookingStatus.COMPLETED:
        action = 'download_contract';
        break;
      default:
        action = 'preview_contract';
    }
    let pdfUrl = `${this.bookingV3Url}/lands/${action}`;
    if (type) {
      pdfUrl += `?${type}=true`;
    }
    if (!this.downloadLandContractByTypeReq.get(type!) || refresh) {
      this.downloadLandContractByTypeReq.set(
        type!,
        this.api.apiDownload(pdfUrl, 'application/pdf').pipe(shareReplay(1))
      );
    }
    return this.downloadLandContractByTypeReq.get(type!)!;
  }

  requestLandBookingOtp() {
    return this.api.apiPost(`${this.mainIntermediaryV3Url}/bookings/lands/request_otp`);
  }

  signLandContract(otpCode: string) {
    return this.api.apiPost(`${this.mainIntermediaryV3Url}/bookings/lands/sign_by_otp`, {
      otp: otpCode,
    });
  }

  uploadConstructionPermit(data: FormData) {
    return this.api
      .apiUpload(`${this.mainIntermediaryV3Url}/bookings/lands/building_permits`, data)
      .pipe(map((res: any) => JsonApi.parseJsonApi(BuildingPermit, res?.data)));
  }

  requestConstructionPeriodExtension(data: any, bookingId: number) {
    return this.api
      .apiUpload(
        `${this.mainIntermediaryV4Url}/bookings/lands/${bookingId}/booking_requests/extend_construction_cert_request`,
        data
      )
      .pipe(map((res: any) => JsonApi.parseJsonApi(BookingRequest, res?.data)));
  }

  uploadConstructionCertificate(data: FormData, bookingId: number | undefined) {
    return this.api
      .apiUpload(`${this.mainIntermediaryV4Url}/bookings/lands/${bookingId}/construction_certs`, data)
      .pipe(map((res: any) => JsonApi.parseJsonApi(ConstructionCert, res?.data)));
  }

  getSubsidyContract() {
    return this.api
      .apiGet(`${this.bookingV3Url}/lands/subsidy_contract`)
      .pipe(map((res: any) => JsonApi.parseJsonApi(LandSubsidyContract, res?.data)));
  }

  submitConveyanceRequest(mohBranch: string) {
    const payload = {
      _jsonapi: {
        data: {
          type: 'conveyances',
          attributes: {
            moh_branch: mohBranch,
          },
        },
      },
    };

    return this.api.apiPost(`${this.mainIntermediaryV2Url}/conveyances`, payload).pipe(
      map((res: any) => {
        return JsonApi.parseJsonApi(Conveyance, res.data);
      })
    );
  }

  signLandContractLater(bookingId: number) {
    return this.api.apiPost(`${this.mainIntermediaryV4Url}/bookings/lands/${bookingId}/sign_contract_later`);
  }

  startOffplanBookingJourney(projectId: number, sourceChannel?: string): Observable<any> {
    return this.api
      .apiPost(`${this.mainIntermediaryV4Url}/bookings/offplans/${projectId}/start_booking`, {
        project_id: projectId,
        source_channel: sourceChannel,
      })
      .pipe(
        switchMap((res: any) => {
          if (res?.data?.topic) {
            return this.queryPollingService.polling(res.data.topic, res.data.request_id);
          } else {
            throw new Error('unexpected behavior');
          }
        })
      );
  }

  fetchBookingToken(projectId: string) {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/bookings/offplans/${projectId}/fetch_booking_token`).pipe(
      map((res) => {
        return res?.data;
      })
    );
  }

  cancelUnsubsidizedBooking(bookingId: string, reasonForm: BookingCancelReasonForm) {
    const payload = createPayload(reasonForm, bookingId, 'unsubsidize_bookings');
    const url = `${this.mainIntermediaryV3Url}/unsubsidize_bookings/${bookingId}/cancel`;
    return this.api.apiPut(url, undefined, payload, 'application/json', false);
  }

  signSalesContract(bookingId: number, projectSaleContractId: number, payload: any) {
    return this.api.apiPut(
      `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contracts/${projectSaleContractId}/sign`,
      '',
      createPayload(payload)
    );
  }

  requestSignSalesContractOtp(bookingId: number, projectSaleContractId: number): any {
    return this.api
      .apiPost(
        `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contracts/${projectSaleContractId}/request_otp`
      )
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      );
  }

  rejectSalesContract(bookingId: number, projectSaleContractId: number, payload: any) {
    return this.api.apiPut(
      `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contracts/${projectSaleContractId}/reject`,
      '',
      createPayload(payload)
    );
  }

  signAnnex(bookingId: number, saleContractAnnexId: number, payload: any) {
    return this.api.apiPut(
      `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contract_annexes/${saleContractAnnexId}/sign`,
      '',
      createPayload(payload)
    );
  }

  requestSignAnnexOtp(bookingId: number, saleContractAnnexId: number): any {
    return this.api
      .apiPost(
        `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contract_annexes/${saleContractAnnexId}/request_otp`
      )
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      );
  }

  rejectAnnex(bookingId: number, saleContractAnnexId: number, payload: any) {
    return this.api.apiPut(
      `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contract_annexes/${saleContractAnnexId}/reject`,
      '',
      createPayload(payload)
    );
  }

  extendThePq(bookingId: string, freeExtendPQFee: boolean) {
    return this.api
      .apiPost(`${this.mainIntermediaryV4Url}/bookings/${bookingId}/extend_price_quotation`, {
        free_extend_pq_fee: freeExtendPQFee,
      })
      .pipe(
        switchMap((res: any) => {
          res = res?.data || res;
          if (res.topic) {
            return this.queryPollingService.polling(res.topic, res.request_id).pipe(
              map((response) => {
                return response;
              })
            );
          } else {
            throw new Error('unexpected behavior');
          }
        })
      );
  }

  getPayInvoice(invoiceNumber: string) {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/bookings/pay_invoices/${invoiceNumber}`).pipe(
      map((res: any) => {
        return res?.data;
      })
    );
  }

  getUnitDetail(bookingId: string | number, params: ParamsInterface = {}): Observable<ProjectUnit> {
    const url = `${this.mainIntermediaryV4Url}/beneficiary/bookings/${bookingId}/unit`;
    return this.api.apiGet(url, undefined, params).pipe(
      map((res: any) => {
        const projectUnit = JsonApi.parseJsonApi(ProjectUnit, res.data, res.included);
        return projectUnit;
      })
    );
  }

  startLandBookingJourney(projectId: number, sourceChannel?: string): Observable<any> {
    return this.api
      .apiPost(`${this.mainIntermediaryV4Url}/bookings/lands/${projectId}/start_booking`, {
        project_id: projectId,
        source_channel: sourceChannel,
      })
      .pipe(
        switchMap((res: any) => {
          if (res?.data?.topic) {
            return this.queryPollingService.polling(res.data.topic, res.data.request_id);
          } else {
            throw new Error('unexpected behavior');
          }
        })
      );
  }

  getSaleContractAnnexes(bookingId: number | string) {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contract_annexes`).pipe(
      map((res: any) => {
        return res?.map((annex: any) => JsonApi.buildFromObject(Annex, annex));
      })
    );
  }

  downloadSaleContract(bookingId: number) {
    if (!this.downloadSaleContractDocumentReq) {
      this.downloadSaleContractDocumentReq = this.api
        .apiDownload(
          `${this.mainIntermediaryV4Url}/beneficiary/bookings/${bookingId}/download_sale_contract_file`,
          'application/pdf'
        )
        .pipe(shareReplay(1));
    }
    return this.downloadSaleContractDocumentReq;
  }

  getSaleContractUrl(bookingId: number) {
    return this.api
      .apiGet(`${this.mainIntermediaryV4Url}/beneficiary/bookings/${bookingId}/download_url?type=sale_contract_file`)
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      );
  }

  downloadAnnex(bookingId: number, saleContractAnnexId: number) {
    return this.api
      .apiDownload(
        `${this.mainIntermediaryV4Url}/bookings/${bookingId}/sale_contract_annexes/${saleContractAnnexId}/download_annex_file`,
        'application/pdf'
      )
      .pipe(shareReplay(1));
  }
}

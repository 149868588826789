<section class="background-light-navy footer-section">
  <footer>
    <div class="footer-container mx-auto overflow-hidden pb-3 pt-9 pt-md-13">
      <div class="row">
        <div class="col-md-7">
          <img src="assets/icons/footer/NHC.svg" height="66px" width="63.09px" />
          <div class="text-white fs-md pt-5">
            {{ 'NHC.FOOTER.SUMMARY' | translate }}
          </div>
          <div class="d-flex flex-row gap-3 mt-5 mb-md-0 mb-7">
            <a href="{{ socialNetworks?.snapchat }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/snapchat.svg" alt="" />
            </a>
            <a href="{{ socialNetworks?.youtube }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/youtube.svg" alt="" />
            </a>
            <a href="{{ socialNetworks?.whatsapp }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/whatsapp.svg" alt="" />
            </a>
            <a href="{{ socialNetworks?.instagram }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/instagram.svg" alt="" />
            </a>
            <a href="{{ socialNetworks?.twitter }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/twitter.svg" alt="" />
            </a>
            <a href="{{ socialNetworks?.facebook }}" class="text-decoration-none" target="_blank">
              <img src="assets/icons/socials/facebook.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="col-md-5 footer-right-image">
          <a href="https://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/6" target="_blank">
            <img class="mt-4 dga-stamp-img" src="assets/images/footer/dga_stamp.png" alt="DGA stamp" />
          </a>
        </div>
      </div>

      <hr class="thick-line text-gray-blue opacity-100" size="auto" />

      <div
        class="d-flex flex-column flex-md-row align-items-md-center w-100 justify-content-between gap-4 flex-sm-column-reverse"
      >
        <div class="text-white fs-xs">
          {{ 'NHC.FOOTER.COPYRIGHT' | translate }}
        </div>
        <div class="gap-5 d-flex">
          <img src="assets/icons/footer/NHC.svg" />
          <img src="assets/icons/footer/vision2030.svg" />
        </div>
      </div>
    </div>
  </footer>
</section>

import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Event as NavigationEvent,
  Router,
} from '@angular/router';
import { CmsService } from '@app/base/services/http/cms.service';
import { LokaliseService } from '@app/base/services/http/lokalise.service';
import { ActivityTrackingService } from '@app/base/services/socket/activity-tracking.service';
import { BookingTokenService } from '@app/base/services/ui/booking-token.service';
import { BreadcrumbService } from '@app/base/services/ui/breadcrumb.service';
import { LoadingEventData, LoadingEventService } from '@app/base/services/ui/loading-events.service';
import { PreviousRouteService } from '@app/base/services/ui/previous-route.service';
import { SakaniSessionService } from '@app/base/services/ui/sakani-session.service';
import { ToastService } from '@app/base/services/ui/toast.service';
import { Constants, currentLang, loadTranslations } from '@app/helpers';
import { Breadcrumb } from '@app/interfaces/breadcrumb.interface';
import { IServerError } from '@app/interfaces/server-error.interface';
import { environment } from '@environment/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { Subscription, interval, of } from 'rxjs';
import { filter, ignoreElements, map, startWith, switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-root', // need to keep app-root selector for the app to work
  templateUrl: './nhc.component.html',
  styleUrls: ['./nhc.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class NhcComponent implements OnInit, OnDestroy, AfterViewInit {
  cmsMetadata: any = {};
  loading: LoadingEventData = {
    status: false,
  };
  pageError?: IServerError;
  isTranslationAvailable = false;
  subscribeRef: Subscription;
  isCollapsed = true;
  isMarketplace = false;
  environment = environment;

  private prevUrl = '';
  private currentUrl = '';

  @ViewChild('cookieModalTpl') cookieModalTpl?: TemplateRef<ElementRef>;
  cookieModalRef?: NgbModalRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private loadingEventService: LoadingEventService,
    private lokaliseSvc: LokaliseService,
    private titleService: Title,
    private breadcrumService: BreadcrumbService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private sakaniSessionService: SakaniSessionService,
    private activityService: ActivityTrackingService,
    private toastSvc: ToastService,
    private bookingTokenService: BookingTokenService,
    private _previousRouteService: PreviousRouteService,
    private ngbModal: NgbModal,
    private cmsService: CmsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.setAnalyticParams(params);
      this.setLang(params.lang);
      this.pageError = undefined;
      this.setAnalyticParams(params);
    });
    // loadTranslations(this.translate, { lang: 'en', data: enData }, { lang: 'ar', data: arData });
    this.subscribeRef = this.loadingEventService.getLoadingEvent.subscribe((loading) => {
      this.loading = loading;
    });
    this.loadingEventService.getServerErrorEvent.subscribe((dataError: any) => {
      this.pageError = dataError;
    });
    this.watchBreadcrumbs();
    this.loadLokaliseTransAndSetPageTitle();
  }

  ngOnInit() {
    this.getCmsContent();
    this.translate.use(currentLang());
    this.document.documentElement.lang = currentLang();
    if (!this.isLogged()) {
      this.bookingTokenService.clear();
    }
  }

  ngAfterViewInit(): void {
    interval(200)
      .pipe(
        startWith(0),
        takeWhile(() => !this.lokaliseSvc.translationLoaded),
        ignoreElements()
      )
      .subscribe({
        complete: () => {
          try {
            if (localStorage.getItem('accept_cookies')) {
              return;
            }
            this.showAcceptCookiesModal();
          } catch (error) {
            console.error(error);
          }
        },
      });
  }

  setAnalyticParams(params: any) {
    this.activityService.setAnalyticParamsToProps(params);
  }

  loadLokaliseTransAndSetPageTitle() {
    // filter navigation end router event
    const navigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // 1. get translation from lokalise and 2. set page title once navigation end event fires
    const navEndSubs: Subscription = navigationEnd
      .pipe(
        // 1. get translation data from lokalise if it is not yet fetched
        switchMap((r) => {
          this.isMarketplace = this.router.isActive('/marketplace', {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored',
          });
          return this.isTranslationAvailable ? of(null) : this.lokaliseSvc.getLocales();
        }),

        // load lokalise translation if it has not been loaded yet
        switchMap((translationResponse) => {
          if (translationResponse !== null) {
            loadTranslations(this.translate, { lang: currentLang(), data: translationResponse });
            this.isTranslationAvailable = true;
          }

          return of(translationResponse);
        })
      )
      .subscribe((r: ActivatedRoute | null) => {
        const pageTitle = this.getNestedRouteTitles().join(' - ');
        // 2. set page title
        this.titleService.setTitle(pageTitle);

        this.activityService.gaPageView(pageTitle, this.activatedRoute.snapshot.queryParams);
      });

    this.subscribeRef.add(navEndSubs);
  }

  ngOnDestroy(): void {
    this.subscribeRef && this.subscribeRef.unsubscribe();
  }

  setLang(lang: string): void {
    if (lang && lang !== localStorage.getItem(Constants.LOCAL_STORAGE_LANGUAGE)) {
      localStorage.setItem(Constants.LOCAL_STORAGE_LANGUAGE, lang);
      location.reload();
    }
  }

  getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];
    while (currentRoute) {
      if (currentRoute?.snapshot?.routeConfig?.data?.title) {
        titles.push(this.translate.instant(currentRoute.snapshot.routeConfig.data.title));
      }
      currentRoute = currentRoute.firstChild;
    }
    return titles;
  }

  isLogged(): boolean {
    return this.sakaniSessionService?.isAuthenticated;
  }

  isProductionMode(): boolean {
    return this.environment.appMode === 'production';
  }

  private watchBreadcrumbs() {
    this.prevUrl = '';
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e: NavigationEvent) => {
          this.prevUrl = this.currentUrl;
          this.currentUrl = (e as NavigationEnd).urlAfterRedirects;
          return {
            prevUrl: this.prevUrl.replace(/\?(.*)$/, ''),
            currentUrl: this.currentUrl.replace(/\?(.*)$/, ''),
          };
        }),
        filter((urls) => {
          return urls.currentUrl != urls.prevUrl;
        }),
        map(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }

          return route;
        })
      )
      .subscribe((r: ActivatedRoute | null) => {
        if (r) {
          this.breadcrumService.setBreadcrumbs(this.createBreadcrumbs(r.root));
        }
      });
  }

  private createBreadcrumbs(route: ActivatedRoute, breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const breadcrumbData = (child.snapshot.data['breadcrumbs'] as Breadcrumb[]) || [];
      for (const breadcrumb of breadcrumbData) {
        if (!breadcrumb) {
          continue;
        }
        let routeURL =
          breadcrumb.path !== undefined ? breadcrumb.path : this.getResolvedUrl(child.snapshot).replace(/\/\//g, '/');
        if (breadcrumb.external_url) {
          routeURL = breadcrumb.external_url;
        }
        breadcrumb.path = routeURL;
        breadcrumbs.push(breadcrumb);
      }

      return this.createBreadcrumbs(child, breadcrumbs);
    }
    return [];
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
  }

  private showAcceptCookiesModal() {
    this.cookieModalRef = this.ngbModal.open(this.cookieModalTpl, {
      windowClass: 'bottom-modal v5 fade desktop-modal-fixed cookie-modal',
      backdrop: 'static',
      backdropClass: 'cookie-backdrop',
    });

    this.cookieModalRef.closed.subscribe((res) => {
      localStorage.setItem('accept_cookies', res);
      // we only use cookie to store authentication token, so there is nothing to do
      // if they reject. We will just keep showing the modal until they accept.
    });
  }

  getCmsContent() {
    this.cmsService.getCmsMetadata().subscribe(
      (data) => {
        this.cmsMetadata = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { currentLang } from '@app/helpers';

@Component({
  selector: 'app-nhc-footer',
  templateUrl: './nhc-footer.component.html',
  styleUrls: ['./nhc-footer.component.scss'],
})
export class NhcFooterComponent implements OnInit {
  @Input() footerDataExtra: any;
  @Input() footerContent: any[] = [];
  @Input() copyRight: any;
  @Input() socialNetworks: any;
  @Input() downloadApp!: { android: string; ios: string };

  currentLang: string;
  footerColumnLabels: any;

  constructor() {
    this.currentLang = currentLang();
  }

  ngOnInit(): void {}
}
